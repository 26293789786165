<template>
  <div
    v-for="(category, cIndex) in directoryData"
    :key="'category' + (category.styleGuideId || cIndex)"
  >
    <div class="bg-primary-red text-white px-4 py-3 rounded-sm leading-4 mb-6 mx-4 font-bold text-lg">
      {{ category.title }}
    </div>

    <div class="flex flex-wrap justify-items-stretch">

      <div class="relative w-1/4 h-auto rounded-sm px-4 pb-8"
        v-for="(styleGuide) in category.children"
        :key="styleGuide.styleGuideName"
        @mouseenter="hoverStateForIndex[styleGuide.styleGuideName]=true"
        @mouseleave="hoverStateForIndex[styleGuide.styleGuideName]=false"
      >

        <div
          v-if="!getSgChildren(styleGuide) && hoverStateForIndex[styleGuide.styleGuideName]"
          class="absolute top-0 left-0 w-full h-full mr-8 mb-6 bg-white opacity-80 cursor-not-allowed">

          <base-svg src="icons/lock.svg" class="text-primary-red mt-3 ml-6" tag="div"/>

        </div>

        <img
          class="w-full h-auto max-h-full cursor-pointer"
          :title="styleGuide.styleGuideName"
          :src="styleGuide.renditions.original.fpo || styleGuide.renditions.original.mezzaninePreview"
          @click="handleSelectedStyleGuide(styleGuide)"
        />

      </div>


    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';

export default {
    name: 'GalleryCategories',

    components: {
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();

        const getSgChildren = (styleGuide) => {
         return styleGuide.isPublicSg || (styleGuide.children && styleGuide.children.length)
        }

        const hoverStateForIndex = ref({})

        const { handleSelectedNode } = useStyleGuideDirectory();
        const handleSelectedStyleGuide = (styleGuide) => {
            if (!getSgChildren(styleGuide)) return
            handleSelectedNode({ nodeData: styleGuide });
        };

        const directoryData = computed(() => store.getters['styleGuideTree/getStyleGuideDirectory']);

        return {
            handleSelectedNode,
            handleSelectedStyleGuide,
            directoryData,
            getSgChildren,
            hoverStateForIndex
        };
    }
};
</script>
